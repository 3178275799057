.bottomTest-MainCont {
  display: flex;
  height: max-content;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 200px;
  gap: 100px;
}
.bottomTest-header {
  text-decoration: underline;
  text-underline-offset: 10px;
  font-weight: 400;
}
.bottomTest-cont {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.bottomTest-contDetailsCont {
  display: flex;
  flex-direction: column;
  border: 2px solid #000;
  gap: 20px;
  width: 400px;
  align-items: center;
  justify-content: center;
  height: 400px;
  padding: 30px 50px;
  text-align: center;
}
.bottomTest-contDetailsCont img {
  width: 150px;
  height: 150px;
  object-fit: fit;
}

/* ! BREAKPOINTS */

/* * For large devices */
@media screen and (max-width: 992px) {
  .bottomTest-MainCont {
    text-align: center;
  }
  .bottomTest-cont {
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }
  .bottomTest-contDetailsCont {
    flex-direction: column;
    align-items: center;
    height: 500px;
    width: 500px;
  }
}

/* * For medium device */
@media screen and (max-width: 576px) {
  .bottomTest-MainCont {
    text-align: center;
  }
  .bottomTest-cont {
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }
  .bottomTest-header {
    text-align: center;
    font-size: 1.5rem;
    text-underline-offset: 8px;
    line-height: 40px;
  }
  .bottomTest-contDetailsCont {
    flex-direction: column;
    align-items: center;
    height: max-content;
    width: 350px;
  }
}

/* * For small devices */
@media screen and (max-width: 390px) {
  .bottomTest-contDetailsCont {
    flex-direction: column;
    align-items: center;
    height: max-content;
    width: 340px;
  }
  .bottomTest-contDetailsCont h1 {
    font-size: 1.5rem;
  }
  .bottomTest-contDetailsCont p {
    font-size: 0.9rem;
  }
  .bottomTest-contDetailsCont img {
    width: 100px;
    height: 100px;
    object-fit: fit;
  }
}
@media screen and (max-width: 320px) {
  .bottomTest-contDetailsCont {
    flex-direction: column;
    align-items: center;
    height: max-content;
    width: 300px;
  }
  .bottomTest-contDetailsCont h1 {
    font-size: 1.5rem;
  }
  .bottomTest-contDetailsCont p {
    font-size: 0.9rem;
  }
  .bottomTest-contDetailsCont img {
    width: 100px;
    height: 100px;
    object-fit: fit;
  }
}
